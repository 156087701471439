<template>
  <div class="order-item">
    <div class="head">
      <!-- <span>下单时间：{{order.createTime}}</span> -->
      <span>订单编号：{{order.orderNo}}</span>
      <span class="down-time" v-if="order.status===0">
        <!-- <i class="iconfont icon-down-time"></i> -->
        <!-- <b>付款截止：{{timeText}}</b> -->
      </span>
      <a @click="$emit('on-delete', order)" href="javascript:;" class="del" v-if="[9].includes(order.status)">删除</a>
    </div>
    <div class="body">
      <div class="column goods">
        <ul>
          <li v-for="goods in order.orderInfoList" :key="goods.id">
            <RouterLink class="image" :to="`/product/${goods.id}`">
              <img :src="goods.image" alt="" />
            </RouterLink>
            <div class="info">
              <p class="name ellipsis-2">{{goods.productName}}</p>
              <p class="attr ellipsis">规格：{{goods.sku}}</p>
            </div>
            <div class="price">¥{{goods.price}}</div>
            <div class="count">x{{goods.payNum}}</div>
          </li>
        </ul>
      </div>
      <!-- <div class="column state">
        <p>{{status[order.status].label}}</p>
        待收货  查看物流
        待评价  评价商品
        已完成  查看评价
        <p @click="$emit('on-logistics', order)" v-if="order.orderState===3"><a class="green" href="javascript:;">查看物流</a></p>
        <p v-if="order.orderState===4"><a class="green" href="javascript:;">评价商品</a></p>
        <p v-if="order.orderState===5"><a class="green" href="javascript:;">查看评价</a></p>
      </div> -->
      <div class="column amount">
        <p class="red">¥{{order.payPrice}}</p>
        <!-- <p>（含运费：¥{{order.postFee}}）</p> -->
        <p>在线支付</p>
      </div>
      <div class="column action">
        <!-- 待支付：立即付款，查看详情，取消订单 -->
        <!-- 待发货：查看详情，再次购买 -->
        <!-- 待收货：确认收货，查看详情，再次购买 -->
        <!-- 待评价：查看详情，再次购买，申请售后 -->
        <!-- 已完成：查看详情，再次购买，申请售后 -->
        <!-- 已取消：查看详情 -->
        <XtxButton @click="$router.push(`/member/pay?orderId=${order.totalPrice}`)" v-if="order.status===0" type="primary" size="small">立即付款</XtxButton>
        <XtxButton @click="$emit('on-confirm', order)" v-if="order.status===3" type="primary" size="small">确认收货</XtxButton>
        <p><a  @click="$router.push(`/member/order/${order.orderNo}`)" href="javascript:;">查看详情</a></p>
        <p @click="$emit('on-cancel', order)" v-if="order.status===0"><a href="javascript:;">取消订单</a></p>
        <!-- <a-button type="link" @click="showModal" v-if="order.status===0" class="btn">取消订单</a-button> -->
        <!-- <p @click="$router.push(`/member/checkout?orderId=${order.id}`)" v-if="[0,1,3,4,9].includes(order.orderState)"><a href="javascript:;">再次购买</a></p> -->
        <!-- <p v-if="[2,3].includes(order.status)"><a href="javascript:;">申请售后</a></p> -->
      </div>
      <!-- <a-modal v-model:visible="visible" title="取消订单" @ok="$emit('on-cancel',order)">
        <p>取消订单后，本单享有的优惠可能会一并取消，是否继续？</p>
        
      </a-modal> -->
    </div>
  </div>
</template>
<script>
import { orderStatus } from '@/api/constants'
import { usePayTime } from '@/hooks'
import { ref } from 'vue'
export default {
  name: 'OrderItem',
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['on-cancel', 'on-delete', 'on-confirm', 'on-logistics'],
  setup (props) {
    const { start, timeText } = usePayTime()
    start(props.order.countdown)

    // const visible = ref(false)
    // const showModal = () => {
    //   visible.value = true;
    // };
    // const handleOk = (e) => {
    //   console.log(e);

    //   visible.value = false;
    // };

    return { orderStatus, timeText }
  }
}
</script>
<style scoped lang="less">
.order-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 1;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 70px;
              height: 70px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                }
                &.attr {
                  color: #999;
                  font-size: 12px;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .price {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      .btn{
        color: #333;
        &:hover{
          color: @xtxColor;
        }
      }
      &.state {
        width: 120px;
        .green {
          color: @xtxColor;
        }
      }
      &.amount {
        width: 200px;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        width: 140px;
        a {
          display: block;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
}
</style>
